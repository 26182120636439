import React from 'react';

class Main extends React.Component {

    render() {
        return (
            <div id="content" className="auto-container" dangerouslySetInnerHTML={{__html: this.props.content}} />
        )
    }
}

export default Main;