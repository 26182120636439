import React from 'react';

class About extends React.Component {
    render() {
        return (<div className="auto-container">
            <div className="sec-title text-center">
                <h2>A propos de <span className="normal-font">Nous</span></h2>
            </div>
            <div className="clearfix">
                <div id="about-content" className="column default-text-column with-margin col-xs-12">
                    <article className="inner-box">
                        <p className='text-justify' dangerouslySetInnerHTML={{__html: this.props.about}}/>
                    </article>
                </div>
            </div>
        </div>);
    }
}

export default About;