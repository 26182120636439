import React from 'react';

import './Alert.css'

const $ = window.$;

class Alert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alert: 'warning',
            title: '',
            message: '',
            show: false
        };
        this.handleAlert = this.handleAlert.bind(this);
    }

    componentDidMount(): void {
        let $alert = $('.Alert');
        $alert.on('alert-event', this.handleAlert);
        $alert.on('click', () => this.setState({show: false}));
    }

    componentWillUnmount(): void {
        $('.Alert').removeEventListener('alert-event');
    }

    handleAlert(event) {
        this.setState({
            alert: event.alert,
            title: event.title,
            message: event.message,
            show: true
        })
    }

    render() {
        return (
            <div className={'Alert' + (this.state.show ? '' : ' hide')}>
                <div className={'alert alert-' + this.state.alert} role="alert">
                    <h4 className="alert-heading text-center">{this.state.title}</h4>
                    <hr/>
                    <div dangerouslySetInnerHTML={{__html: this.state.message}}/>
                </div>
            </div>
        );
    }
}

export default Alert;

export function sendAlert(event) {
    let alertElement = $('.Alert');
    if (alertElement) {
        alertElement.trigger(event);
    }
}