import axios from "axios";

export const getClient = async (url, authorization) => {
    try {
        let response = await axios.get(url, {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${authorization}`
            }
        });
        console.log("response.data", response.data);
        return response.data;
    } catch (e) {
        console.warn(e);
        return e.response.data;
    }
}