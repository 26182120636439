import React from 'react';

import './Actions.css'

class Actions extends React.Component {
    /*
    <p><a href="https://barinta.s3.eu-central-1.amazonaws.com/files/Bilan_Barinta.pdf">École de Diallola</a></p>
    <p><a href="https://barinta.s3.eu-central-1.amazonaws.com/files/Bilan_Karamokola.pdf">École de Karamokola</a></p>
    [{"title": "École de Diallola",
    "link": "/?version=v1",
    "description": "<a target='__blank__' href='https://barinta.s3.eu-central-1.amazonaws.com/files/Bilan_Barinta.pdf'>Voir le bilan</a>"}, {"title": "École de Karamokola",
    "link": "/?version=v2",
    "description": "<a target='__blank__' href='https://barinta.s3.eu-central-1.amazonaws.com/files/Bilan_Karamokola.pdf'>Voir le bilan</a>"}]
    * */
    render() {
        return (<div id="actions-content" className="auto-container">
            <div className="sec-title text-center">
                <h2>Nos actions</h2>
            </div>
            <div className="clearfix">
                <div className="column default-text-column with-margin col-xs-12">
                    <article id="barinta-actions" className="inner-box">
                        <h2>Les actions de Barinta</h2>
                        <p className="text-justify">
                            Notre objectif est de réaliser une action chaque année un nouveau collège.<br/>
                            En fonction du besoin recensé sur le collège, nous nous reunissons, avec la contribution de
                            toute personne
                            partageant les mêmes idées que Barinta, pour combler ce besoin. <br/>
                            La force de Barinta se trouve dans sa proximité avec les collèges
                        </p>
                        <h3>Nos objectifs</h3>
                        <ul className="styled-list-one padd-top-20">
                            <li>Donner aux enfants le goût des études.</li>
                            <li>Montrer l'importance d'un suivi régulier des enfants.</li>
                            <li>Étendre le projet dans d'autres collèges dans les mêmes situations que Barinta.</li>
                            <li><b>Chaque enfant a le droit d'avoir une chance de réussir.</b></li>
                        </ul>
                    </article>
                    <article id="last-action">
                        {(this.props.actions || []).length > 0 ? <h3>Les dernières actions de Barinta</h3> : null}
                        {
                            typeof this.props.actions !== 'string' ?
                                <div id="last-action-content">
                                    <dl className="actions">
                                        {(this.props.actions || []).map((action, index) =>
                                            <React.Fragment key={index}>
                                                <dt><a href={action.link}>{action.title}</a></dt>
                                                <dd dangerouslySetInnerHTML={{__html: action.description}}/>
                                            </React.Fragment>
                                        )}
                                    </dl>
                                </div> :
                                <div id="last-action-content" dangerouslySetInnerHTML={{__html: this.props.actions}}/>
                        }
                    </article>
                </div>
            </div>
        </div>);
    }
}

export default Actions;