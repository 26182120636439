import React from 'react';

const $ = window.$;

class Media extends React.Component {
    componentDidMount() {
        $(".fancybox").fancybox({
            openEffect: "none",
            closeEffect: "none"
        });
    }

    render() {
        return (<div className="auto-container">
            <div id="barinta-images-title" className="sec-title text-center">
                <h2>{this.props.mediaTitle}</h2>
            </div>
            <div id="medias" className='list-group gallery'>
                <div className="row">
                    {(this.props.medias || []).map((media, index) =>
                        <div key={index} className='col-sm-4 col-xs-6 col-md-3 col-lg-3'>
                            <a className="thumbnail fancybox" rel="ligthbox" href={media.url}>
                                <img style={{height: 150}} className="img-responsive" alt="" src={media.url}/>
                                <div className='text-right'>
                                    <small className='text-muted'>{media.title}</small>
                                    <small>{media.description}</small>
                                </div>
                            </a>
                        </div>)
                    }
                </div>
            </div>
        </div>);
    }
}

export default Media;