import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import './App.css';
import Header from './Header'
import DonationInfo from './DonationInfo'
import Introduction from './Introduction';
import Repartition from './Repartition';
import Main from './Main';
import CheckoutForm from './CheckoutForm';
import Actions from './Actions';
import About from './About';
import Contact from './Contact';
import Alert from './Alert';
import Media from "./Media";
import Footer from "./Footer";

const $ = window.$;
const InlineEditor = window.InlineEditor;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

function App(props) {
    const [introduction, setIntroduction] = useState('<h4>Chaque individu a le droit d\'apprendre, d\'avoir la chance de construire son avenir...</h4>');
    const [collectedAmount, setCollectedAmount] = useState(0);
    const [donators, setDonators] = useState(0);
    const [targetAmount, setTargetAmount] = useState(0);
    const [content, setContent] = useState('Chargement...');
    const [about, setAbout] = useState('');
    // const [publishableKey, setPublishableKey] = useState('');
    const [repartition, setRepartition] = useState([]);
    const [medias, setMedias] = useState([]);
    const [imageTitle, setImageTitle] = useState('Barinta en image');
    const [actions, setActions] = useState([]);


    handlePreloader();

    const handleError = (error) => {
        console.log('error', error);
    }

    const moveTo = (elem, event) => {
        if (event) {
            event.preventDefault();
            $("#navigation li.current").removeClass("current");
            event.target.parentElement.classList.add("current")
        }
        $("html, body").animate({scrollTop: $("#" + elem).offset().top}, "slow");
    }

    const progress = () => {
        return targetAmount > 0 ? Math.floor((collectedAmount / targetAmount) * 100) : 0;
    }

    const getStats = () => {
        let query = parseQueryString();
        let edition = query.version || query.edition || '';
        let version = `${!edition.startsWith('.') && edition.length > 0 ? '.' : ''}${edition}`;
        fetch(`/api/stripe/stats?version=${version}`)
            .then(res => res.json())
            .then(stats => {
                console.log('stats', stats);
                setCollectedAmount(stats.amount);
                setDonators(stats.count);
            }).catch(handleError);
        headerStyle();
    }

    useEffect(() => {
        const setEditable = (editable, data) => {
            InlineEditor.create(document.querySelector(editable))
                .then(editor => {
                    editor.isReadOnly = true;
                }).catch(handleError);
        }
        let query = parseQueryString();
        let edition = query.version || query.edition || '';
        let version = `${!edition.startsWith('.') && edition.length > 0 ? '.' : ''}${edition}`;

        let keys = 'barinta.medias,barinta.members,barinta.content,barinta.target,' +
            'barinta.title,barinta.team.about,barinta.introduction,barinta.lastAction,' +
            'barinta.images.title,barinta.actions,barinta.repartition';
        keys = keys.split(',')
            .map(item => `${item}${version}`)
            .reduce((cumul, curr) => {
                return cumul.length === 0 ? curr : (cumul + ',' + curr)
            }, '');

        fetch(`/api/data/values?keys=${keys},stripe.publishableKey`)
            .then(res => res.json())
            .then(dataList => {
                for (let user in dataList) {
                    if (dataList.hasOwnProperty(user)) {
                        let userData = dataList[user];
                        console.log('userData', userData);
                        document.title = extract(userData, 'barinta.title' + version).inputValue;
                        // let publishableKeyValue = extract(userData, 'stripe.publishableKey').inputValue;
                        let repartitionText = extract(userData, 'barinta.repartition' + version).inputValue || '';
                        let repartitionValue = repartitionText.startsWith('[') ? JSON.parse(repartitionText) : repartitionText;
                        let introductionValue = extract(userData, 'barinta.introduction' + version).inputValue;
                        let targetAmountValue = extract(userData, 'barinta.target' + version).inputValue;
                        let contentValue = extract(userData, 'barinta.content' + version).inputValue;
                        let actionsValue = extract(userData, 'barinta.lastAction' + version).inputValue || '';
                        if (actionsValue.length > 0 && actionsValue.startsWith('[')) {
                            actionsValue = JSON.parse(actionsValue);
                        }
                        let aboutValue = extract(userData, 'barinta.team.about' + version).inputValue || '';
                        let mediasValue = extract(userData, 'barinta.medias' + version).inputValue || '';
                        let imageTitleValue = extract(userData, 'barinta.images.title' + version).inputValue || '';
                        if (mediasValue.length > 0 && mediasValue.startsWith('[')) {
                            mediasValue = JSON.parse(mediasValue);
                        }
                        if (contentValue) {
                            // setPublishableKey(publishableKeyValue)
                            setRepartition(repartitionValue)
                            setIntroduction(introductionValue)
                            setTargetAmount(targetAmountValue)
                            setContent(contentValue)
                            setActions(actionsValue)
                            setAbout(aboutValue)
                            setMedias(mediasValue)
                            setImageTitle(imageTitleValue)
                            setEditable('div#introduction', userData['barinta.introduction' + version]);
                            setEditable('div#content', userData['barinta.content' + version]);
                            // setEditable('div#repartition', userData['barinta.repartition' + version]);
                        }
                    }
                }
            }).catch(handleError);
        fetch(`/api/stripe/stats?version=${version}`)
            .then(res => res.json())
            .then(stats => {
                console.log('stats', stats);
                setCollectedAmount(Math.floor(100 * stats.amount) / 100);
                setDonators(stats.count);
            }).catch(handleError);
        headerStyle();
    }, []);

    const handleSuccess = (event) => {
        getStats();
    }
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Header moveTo={moveTo}/>
            <Alert/>
            <section id="cause-section" className="urgent-cause">
                <CauseSection targetAmount={targetAmount}
                              donators={donators}
                              collectedAmount={collectedAmount}
                              introduction={introduction}
                              repartition={repartition}
                              progress={progress()}
                              moveTo={moveTo.bind(this, 'donation-section')}/>
            </section>
            <section id="default-section" className="default-section">
                <Main content={content}/>
            </section>
            <section id="donation-section" className="donation-section">
                {/*<DonationForm publishableKey={this.state.publishableKey}/>*/}
                <CheckoutForm onSuccess={handleSuccess}/>
            </section>
            <section id="actions-section" className="actions-section">
                <Actions actions={actions}/>
            </section>
            <section id="media-section" className="gallery-section full-width">
                <Media medias={medias} mediaTitle={imageTitle}/>
            </section>
            <section id="about" className="about-section">
                <About about={about}/>
            </section>
            <section id="contact" className="contact-section extended">
                <Contact/>
            </section>
            <footer className="main-footer">
                <Footer/>
            </footer>
            <div className="scroll-to-top scroll-to-target" data-target=".main-header" style={{display: 'block'}}>
                <span className="fa fa-arrow-up"/>
            </div>
        </div>
    );
}

function CauseSection(props) {
    return (
        <div className="auto-container">
            <div className="row clearfix">
                <div className="column col-lg-6 col-md-6 col-xs-12">
                    <Introduction moveTo={props.moveTo} content={props.introduction}/>
                </div>
                <div className="column col-lg-6 col-md-6 col-xs-12">
                    <div className="row clearfix">
                        <DonationInfo progress={props.progress} donators={props.donators}
                                      targetAmount={props.targetAmount}
                                      collectedAmount={props.collectedAmount}/>
                    </div>
                    <Repartition repartition={props.repartition}/>
                </div>
            </div>
        </div>
    )
}

function parseQueryString() {
    var queryString = window.location.search;
    queryString = queryString.substring(1);

    var params = {}, queries, temp, i, l;

    // Split into key/value pairs
    queries = queryString.split("&");

    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }

    return params;
}

function extract(data, key) {
    return data[key] || {}
}

function handlePreloader() {
    if ($('.preloader').length) {
        $('.preloader').delay(500).fadeOut(500);
    }
}

function headerStyle() {
    if ($('.main-header').length) {
        var windowpos = $(window).scrollTop();
        if (windowpos >= 80) {
            $('.main-header').addClass('fixed-header');
            $('.scroll-to-top').fadeIn(300);
        } else {
            $('.main-header').removeClass('fixed-header');
            $('.scroll-to-top').fadeOut(300);
        }
    }

    if ($('.main-header li.dropdown ul').length) {
        $('.main-header li.dropdown').append('<div class="dropdown-btn"></div>');

        //Dropdown Button
        $('.main-header li.dropdown .dropdown-btn').on('click', function () {
            $(this).prev('ul').slideToggle(500);
        });
    }

    if ($('.scroll-to-target').length) {
        $(".scroll-to-target").on('click', function () {
            var HeaderHeight = $('.header-lower').height();
            var target = $(this).attr('data-target');
            // animate
            $('html, body').animate({
                scrollTop: $(target).offset().top - HeaderHeight
            }, 1000);

        });
    }
}

export default App;
