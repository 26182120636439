import React from 'react';

class Footer extends React.Component {

    render() {
        return (
            <div>
                <div className="footer-bottom">
                    <div className="auto-container clearfix">
                        <div className="copyright text-center">Copyright {new Date().getFullYear()} © Barinta</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;