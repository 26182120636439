import React from 'react';
import './DonationInfo.css';

const $ = window.$;

class DonationInfo extends React.Component {

    updateInfo() {
        $('.donation').appear(function () {
            var elm = $(this);
            var color = elm.attr('data-fgColor');
            var perc = elm.attr('value');
            elm.knob({
                'value': 0,
                'min': 0,
                'max': 100,
                'skin': 'tron',
                'readOnly': true,
                'thickness': 0.15,
                'dynamicDraw': true,
                'displayInput': false
            });

            $({value: 0}).animate({value: perc}, {
                duration: 1000,
                easing: 'swing',
                progress: function () {
                    elm.val(Math.ceil(this.value)).trigger('change');
                }
            });

            //circular progress bar color
            $(this).append(function () {
                elm.parent().parent().find('.circular-bar-content').css('color', color);
                elm.parent().parent().find('.circular-bar-content label').text(perc + '%');
            });

        }, {accY: 20});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.progress !== this.props.progress) {
            this.updateInfo();
        }
    }

    render() {
        return (
            <div className="row clearfix">
                {/* Column */}
                <div className="column circular-graph col-lg-6 col-md-6 col-xs-12">
                    <div className="inner-box">
                        <div className="graph-outer">
                            <input id="input-progress" type="text" className="donation" data-fgcolor="#fb5e1c"
                                   data-bgcolor="none" data-width="200" data-height="200" data-linecap="round"
                                   value={this.props.progress} onChange={() => console.log('value changed')}/>
                            <div className="inner-text">
                                <span id="progress"
                                      className="exbold-font">{this.props.progress}</span><sub>%</sub><br/>
                                <span className="status">Atteints</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Column */}
                <div className="column col-lg-6 col-md-12 col-xs-12">
                    <div className="inner-box">
                        <ul className="cause-list">
                            <li className="clearfix"><span className="pull-left">
                                Donateurs -</span> <strong id="donators"
                                                           className="pull-right">{this.props.donators}</strong></li>
                            {/* FIXME contributors count */}
                            <li className="clearfix"><span className="pull-left">
                                Objectif -</span> <strong id="target"
                                                          className="pull-right">{Math.floor(100 * this.props.targetAmount) / 100} €</strong>
                            </li>
                            {/* FIXME target amount */}
                        </ul>
                        <div className="total-collected">
                            <span id="collected">{Math.floor(100 * this.props.collectedAmount) / 100}</span> € {/* FIXME collected amount */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DonationInfo;

