import React from 'react'
import './Repartition.css'

class Repartition extends React.Component {

    render() {
        return (
            typeof this.props.repartition !== 'string' ?
                <div id="repartition">
                    <h3><strong>Répartition du budget</strong></h3>
                    <ul>
                        {this.props.repartition.map((repartition, index) => {
                            return <li key={index} data-value={repartition.value}
                                       data-content={repartition.text}>- {repartition.value}% : {repartition.text}</li>
                        })}
                    </ul>
                </div>
                : <div id="repartition" dangerouslySetInnerHTML={{__html: this.props.repartition}}/>
        )
    }
}

export default Repartition;