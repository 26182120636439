import React from 'react';
import './Header.css'

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: false
        };
    }

    moveTo(id) {
        console.log('id', id);
        return false;
    }

    render() {
        return (
            <header className="main-header">
                <div className="header-upper">
                    <div className="auto-container clearfix">
                        <div className="logo">
                            <a href="/"><img src="images/logo-1.png" alt="Radium"/></a>
                        </div>
                        <div id="info-outer" className="info-outer">
                            <div className="info-box">
                                <div className="icon"><span className="flaticon-interface"/></div>
                                <strong>EMAIL</strong>
                                <a href="mailto:association@barinta.org">association [at] barinta [dot] org</a>
                            </div>
                            <div className="info-box">
                                <div className="icon"><span className="flaticon-technology"/></div>
                                <strong>Téléphone</strong>
                                <a href="/#">(+33)06.20.79.09.21</a>
                            </div>
                            <div className="info-box">
                                <div className="icon"><span className="flaticon-three"/></div>
                                <strong>Connexion</strong>
                                <a href="/#">{this.state.loggedIn ? 'Logout' : 'Login'}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-lower">
                    <div className="auto-container clearfix">

                        <div className="outer-box clearfix">

                            <nav className="main-menu">

                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse"
                                            data-target=".navbar-collapse">
                                        <span className="icon-bar"/>
                                        <span className="icon-bar"/>
                                        <span className="icon-bar"/>
                                    </button>
                                </div>
                                <div className="navbar-collapse collapse clearfix pull-right">
                                    <ul id="navigation" className="navigation">

                                        <li>
                                            <a href="/#cause-section" onClick={this.props.moveTo.bind(this, 'cause-section')}>Home</a>
                                        </li>
                                        <li>
                                            <a href="/#donation-section" onClick={this.props.moveTo.bind(this, 'donation-section')}>Faire un don</a>
                                        </li>
                                        <li>
                                            <a href="/#actions-section" onClick={this.props.moveTo.bind(this, 'actions-section')}>Nos actions</a>
                                        </li>
                                        <li>
                                            <a href="/#medias" onClick={this.props.moveTo.bind(this, 'media-section')}>Nos images</a>
                                        </li>
                                        <li>
                                            <a href="/#about" onClick={this.props.moveTo.bind(this, 'about')}>A propos de nous</a>
                                        </li>
                                        <li>
                                            <a href="/#contact" onClick={this.props.moveTo.bind(this, 'contact')}>Nous contacter</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;