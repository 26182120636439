import React from 'react';

import './Contact.css'
import {sendAlert} from './Alert'

const $ = window.$;

class Contact extends React.Component {
    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            submitting: false,
            submitted: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            submitted: false,
            submitting: true
        });
        let form = event.target;
        let body = $(event.target).serialize();
        fetch('/api/mailing/feedback', {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded', 'Accept': 'application/json'},
            body: body
        })
            .then(res => res.json())
            .then(response => {
                console.log('response', response);
                this.setState({
                    name: '',
                    email: '',
                    phone: '',
                    message: '',
                    submitted: true,
                    submitting: false
                });
                form.reset();
                sendAlert({
                    type: 'alert-event',
                    alert: 'success',
                    title: 'Contact',
                    message: "Merci pour votre message"
                });
            })
            .catch(error => {
                console.error(error);
                this.setState({
                    submitting: false
                });
            });
    }

    handleChange(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]: value});
    }

    hideMessage(event) {
        event.preventDefault();
        this.setState({
            submitted: false
        });
    }

    render() {
        return (
            <>
                {!this.state.submitted ? <div className="auto-container">
                        <div className="inner-container">

                            <div className="sec-title text-center">
                                <h2>Notre <span className="normal-font">Contact</span></h2>
                            </div>

                            <div className="contact-info row clearfix">
                                <div className="info-column col-lg-4 col-md-6 col-xs-12">
                                    <div className="inner-box">
                                        <div className="icon"><span className="flaticon-location"/></div>
                                        <h4>ADDRESS</h4>
                                        6 Impasse Jean Moulin,<br/>
                                        92390 Villeneuve-la-Garenne
                                    </div>
                                </div>
                                <div className="info-column col-lg-4 col-md-6 col-xs-12">
                                    <div className="inner-box">
                                        <div className="icon"><span className="flaticon-technology"/></div>
                                        <h4>Phone</h4>
                                        0620790921
                                    </div>
                                </div>
                                <div className="info-column col-lg-4 col-md-6 col-xs-12">
                                    <div className="inner-box">
                                        <div className="icon"><span className="flaticon-interface"/></div>
                                        <h4>Email</h4>
                                        <a href="mailto:association@barinta.org">association [at] barinta [dot] org</a>,
                                    </div>
                                </div>
                            </div>

                            <div className="contact-form-container wow zoomInStable animated c-animation"
                                 data-wow-delay="0ms"
                                 data-wow-duration="2500ms">
                                <form method="post" action="" id="contact-form" onSubmit={this.handleSubmit}>
                                    <div className="row clearfix">
                                        <p id="contact-errors" className="error"/>
                                        <div className="column col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <input type="text" name="name" value={this.state.name}
                                                       placeholder="Nom complet"
                                                       onChange={this.handleChange} required={true}/>
                                            </div>
                                            <div className="form-group">
                                                <input type="email" name="email" value={this.state.email}
                                                       placeholder="Email"
                                                       onChange={this.handleChange} required={true}/>
                                            </div>

                                            <div className="form-group">
                                                <input type="text" name="phone" value={this.state.phone}
                                                       placeholder="Téléphone"
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="column col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                        <textarea name="message" value={this.state.message}
                                                  placeholder="Message"
                                                  onChange={this.handleChange}/>
                                            </div>
                                        </div>

                                        <div className="column col-md-12 col-sm-12 col-xs-12">

                                            <div className="column col-md-6 col-sm-12 col-xs-12 g-recaptcha"
                                                 data-sitekey="6LfODCQUAAAAAHLgNd2rD40KszNwB4OZE5LKUEQx"/>

                                            <div className="form-group col-md-6 col-xs-12">
                                                <button type="submit" name="submit"
                                                        className={'theme-btn btn-style-two c-theme-btn' + (this.state.submitting ? ' spin' : '')}>
                                                    Envoyer <span className={(this.state.submitting ? ' spinner' : '')}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> :
                    <div className="auto-container">
                        <div className="sec-title">
                            <h1>Merci pour votre message</h1>
                            <h2><a href="/#" onClick={this.hideMessage}>OK</a></h2>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default Contact;