import React from 'react';
import './Introduction.css'

class Introduction extends React.Component {

    render() {
        return (
            <article className="inner-box">
                <div id="introduction" dangerouslySetInnerHTML={{__html: this.props.content}}/>
                <a className="theme-btn btn-style-one" href="/#donation-section"
                   onClick={this.props.moveTo}>
                    Faire un don</a>
            </article>
        )
    }
}

export default Introduction;