import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import {getClient} from "./utils";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(0)
        },
        '& .MuiFormLabel-root': {
            fontSize: "16px"
        },
        '& .MuiInputBase-root': {
            fontSize: "16px"
        },
        '& .MuiFormHelperText-root': {
            fontSize: "1rem"
        }
    },
}));

const MIN_AMOUNT = 100;
const PAYMENT_CODE_LEN = 6;
const MIN_PHONE_LEN = 8;

const TOKEN = process.env.REACT_APP_ORANGE_AUTHORIZATION; // Test

export default function WebPaymentForm(props) {
    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [paymentCode, setPaymentCode] = useState("");
    const [currency, setCurrency] = useState("XOF");
    const [lang, setLang] = useState("fr");
    const [amount, setAmount] = useState('');
    const [showAmount, setShowAmount] = useState(false);
    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [succeeded, setSucceeded] = useState(false);
    const [merchantKey, setMerchantKey] = useState('');
    const [orderId, setOrderId] = useState('');
    const [clientName, setClientName] = useState('Djamma Orange Web Payment');

    document.title = clientName

    const authorization = TOKEN;

    const createPayment = async () => {
        // Create PaymentIntent as soon as the page loads
        let body = {
            phoneNumber,
            paymentCode,
            amount,
            currency,
            lang
        };
        if (merchantKey) {
            body['merchant_key'] = merchantKey;
        }
        if(orderId) {
            body['order_id'] = orderId;
        }
        return fetch("/api/orange/payment", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${authorization}`,
                "x-metadata-origin": window.location.hostname
            },
            body: JSON.stringify(body)
        })
            .then(res => {
                return res.json();
            });
    };

    const retrieveClientInformation = async () => {
        if (authorization && authorization.length > 0) {
            let data = await getClient(`/api/orange/client`, authorization)
            if (data.code && data.code / 100 !== 2) {
                setError(data);
            } else {
                setClientName(data.name);
            }
        }
    }

    const retrieveInformation = () => {
        if (authorization && authorization.length > 0) {
            setShowAmount(false);
            if (orderId && orderId.length > 0) {
                setShowAmount(false);
                fetch(`/api/orange/complete?orderId=${orderId}`, {
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${authorization}`
                    }
                })
                    .then(res => {
                        if (res.status !== 200) {
                            return Promise.reject(res)
                        }
                        return res.json();
                    })
                    .then(data => {
                        console.log('data', data);
                        setAmount(data.amount);
                        setLang(data.lang)
                        setCurrency(data.currency);
                        setSucceeded(data.success);
                        setMerchantKey(data.merchant_key)
                    })
                    .catch(error => {
                        console.log('error', error);
                        setShowAmount(true);
                    });
            } else {
                setShowAmount(true);
            }
        }
        retrieveClientInformation();
    }

    useEffect(retrieveInformation, []);

    const handleSubmit = (ev) => {
        ev.preventDefault();
        setProcessing(true);
        createPayment().then(res => {
            console.log('res', res);
            if (res.code) {
                setError(`Payment failed ${res.message || res.description}`);
                setProcessing(false);
            } else {
                if(typeof props.onSuccess === 'function') {
                    props.onSuccess(res);
                }
                setError(null);
                setProcessing(false);
                setSucceeded(true);
                console.log(res);
                if(res.entity) {
                    setOrderId(res.entity.order_id);
                    setMerchantKey(res.entity.merchant_key)
                }
            }
        }).catch(error => {
            console.log('error', error);
            setError(`Payment failed ${error.message}`);
            setProcessing(false);
        });
    }
    return (
        <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
            <h1>{clientName}</h1>
            {orderId && <h2>Order Id : {orderId}</h2>}
            <TextField fullWidth
                       id="standard-error"
                       label="Phone Number"
                       value={phoneNumber}
                       disabled={succeeded}
                       placeholder={"7701100250"}
                       required
                       onChange={e => {
                           setPhoneNumber(e.target.value);
                           setDisabled(e.target.value.length < MIN_PHONE_LEN)
                       }}
            />
            <TextField fullWidth
                       id="standard-error"
                       label="Payment Code"
                       value={paymentCode}
                       disabled={succeeded}
                       placeholder={"123456"}
                       required
                       helperText={"#144#77# for payment code."}
                       onChange={e => {
                           setPaymentCode(e.target.value)
                           setDisabled(e.target.value.length !== PAYMENT_CODE_LEN)
                       }}
            />
            <TextField fullWidth
                       id="standard-error"
                       label="Montant"
                       type={"number"}
                       value={amount}
                       placeholder={"10 000 FCFA"}
                       required
                       disabled={!showAmount || succeeded}
                       onChange={e => {
                           setAmount(Boolean(e.target.value) ? (e.target.value * 1) : '')
                           setDisabled(e.target.value * 1 < MIN_AMOUNT)
                       }}
            />

            <button
                disabled={processing || disabled || succeeded}
                id="submit"
            >
                <span id="button-text">
                  {processing ? (
                      <div className="spinner" id="spinner"></div>
                  ) : (
                      "Orange Web Payment"
                  )}
                </span>
            </button>
            {/* Show any error that happens when processing the payment */}
            {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            {/* Show a success message upon completion */}
            <p className={succeeded ? "result-message" : "result-message hidden"}>
                Payment complété. Merci !
            </p>
        </form>
    );
}